let origin = "https://future.zhuneng.cn";
//发表社区共建
const postWishUrl = `/gateway/hc-govern/miniapi/communityConstruction/add`;
//我的社区共建
const myWishUrl = `/gateway/hc-govern/miniapi/communityConstruction/mineList`;
//共建详情
const wishDetailUrl = `/gateway/hc-govern/miniapi/communityConstruction/detailInfo`;
//社区共建墙
const wishWallUrl = `/gateway/hc-govern/miniapi/communityConstruction/list`;
export { postWishUrl, myWishUrl, wishDetailUrl, wishWallUrl };
